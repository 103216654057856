@font-face {
    font-family: 'Roboto Black';
    src: url('./Roboto/Roboto-Black.ttf');
    src: local('Roboto Black') format('truetype');
    font-weight: 900;
  }
  
  @font-face {
    font-family: 'Roboto Black Italic';
    src: url('./Roboto/Roboto-BlackItalic.ttf');
    src: local('Roboto Black Italic') format('truetype');
    font-weight: 900;
  }
  

  @font-face {
    font-family: 'Roboto Bold';
    src: url('./Roboto/Roboto-Bold.ttf');
    src: local('Roboto Bold') format('truetype');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Roboto Bold Italic';
    src: url('./Roboto/Roboto-BoldItalic.ttf');
    src: local('Roboto Bold Italic') format('truetype');
    font-weight: 700;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('./Roboto/Roboto-Regular.ttf');
    src: local('Roboto') format('truetype');
    font-weight: 400;
  }


  @font-face {
    font-family: 'Roboto Italic';
    src: url('./Roboto/Roboto-Italic.ttf');
    src: local('Roboto Italic') format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Roboto Light';
    src: url('./Roboto/Roboto-Light.ttf');
    src: local('Roboto Light') format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Roboto Light Italic';
    src: url('./Roboto/Roboto-LightItalic.ttf');
    src: local('Roboto Light Italic') format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Roboto Medium';
    src: url('./Roboto/Roboto-Medium.ttf');
    src: local('Roboto Medium') format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Roboto Medium Italic';
    src: url('./Roboto/Roboto-MediumItalic.ttf');
    src: local('Roboto Medium Italic') format('truetype');
    font-weight: 500;
  }


  @font-face {
    font-family: 'Roboto Thin';
    src: url('./Roboto/Roboto-Thin.ttf');
    src: local('Roboto Thin') format('truetype');
    font-weight: 100;
  }

  @font-face {
    font-family: 'Roboto Thin Italic';
    src: url('./Roboto/Roboto-ThinItalic.ttf');
    src: local('Roboto Thin Italic') format('truetype');
    font-weight: 100;
  }